<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex align-items-center">
          <div class="col-5 col-sm-5">
            <!-- <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search.search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form> -->
            <div>
              <el-input clearable @clear="doSearch()" size="small" class="input-with-select" prefix-icon="el-icon-search" placeholder="Search here..." v-model="search.search_keyword">
                <el-select v-model="search.search_by" slot="append">
                  <el-option v-for="(val, index) in search_by_options" :key="index" :label="val.text" :value="val.value"></el-option>
                </el-select>
              </el-input>
            </div>
          </div>
          <div class="col-3 col-sm-3" style="margin-left: -20px;">
            <el-button type="info" size="small" @click="doSearch()">Search</el-button>
          </div>
          <div class="col-4 col-sm-4">
            <button class="btn btn-primary float-right" @click="$router.push('/chatbot-templates/create')">{{ $t('chatbot_template.btn_create_new') }}</button>
          </div>
        </div>
        <hr/>
        <div class="table-responsive mt-2">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Keywords</th>
                <th>Response Type</th>
                <th>Response Preview</th>
                <th>Scenario</th>
                <th>Created Date</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.title }}</td>
                <td>
                  <el-tag size="mini" v-for="(keyword, i1) in item.keyword" :key="i1" class="mr-2">{{ keyword }}</el-tag>
                </td>
                <td>{{ upperFirst(item.body_type) }}</td>
                <td>
                  <el-popover
                    placement="right"
                    width="400"
                    trigger="hover">
                    <h6 v-if="item.header" class="mb-2">Header</h6>
                    <p v-if="item.header" class="mb-2">
                      <font-awesome :icon="item.header.icon"/>&nbsp; {{ item.header.text || item.header.type_str }}
                    </p>
                    <h6 class="mb-2">Body</h6>
                    <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">
                      <i class="el-icon-picture-outline" v-if="item.body_type == 'image'"/>
                      <i class="el-icon-video-camera" v-if="item.body_type == 'video'"/>
                      <i class="el-icon-document" v-if="item.body_type == 'document'"/>
                      <span>
                        {{ ['text', 'interactive'].includes(item.body_type) ? item.body : item.caption }}
                      </span>
                    </p>
                    <template v-if="item.footer && item.footer.text">
                      <h6 class="mb-2">Footer</h6>
                      <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.footer.text }}</p>
                    </template>
                    <h6 v-if="item.button_content_mapped" class="mb-2">Buttons</h6>
                    <p class="mb-2">
                      <span v-for="(btn, ibtn) in item.button_content_mapped" :key="ibtn">
                        <font-awesome :icon="btn.icon"/>&nbsp; {{ btn.text }} ({{btn.type_str}})<br/>
                      </span>
                    </p>
                    <h6 v-if="item.list_content_mapped" class="mb-2">List</h6>
                    <p class="mb-2">
                      <span v-for="(btn, ibtn) in item.list_content_mapped" :key="ibtn">
                        <font-awesome :icon="btn.icon"/>&nbsp; {{ btn.text }}<br/>
                      </span>
                    </p>
                    <span @click="visible = !visible" slot="reference" style="padding: 6px">
                      {{ truncate(['text', 'interactive'].includes(item.body_type) ? item.body : item.caption, 35) }}
                    </span>
                  </el-popover>
                </td>
                <td>{{ item.scenario_type == 'cs' ? 'Customer Service' : 'Normal' }}</td>
                <td>{{ item.created_at_str }}</td>
                <td>
                  <div class="d-flex align-items-end">
                    <button class="btn btn-outline-primary btn-sm" @click="handlerBtnEdit(item.id)">
                      <i class="ic-edit" />
                    </button> &nbsp;
                    <button class="btn btn-outline-primary btn-sm" @click="handlerBtnDelete(item.id)">
                      <i class="ic-delete" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty :image="emptyBot" v-if="total == 0" description="No chatbot templates"></el-empty>
          <b-pagination v-if="total > search.per_page" v-model="currentPage" :total-rows="total" :per-page="search.per_page"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emptyBot from '@/assets/images/undraw/no-bot.svg';
import moment from 'moment';
import { upperFirst, isEqual } from 'lodash';
import chatbotTemplates from '../../../api/chatbotTemplates';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  metaInfo: {
    title: 'Chatbot Templates',
  },
  name: 'ChatbotTemplateIndex',
  data() {
    return {
      emptyBot,
      upperFirst,
      search: {
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
        search_keyword: this.$route.query.search_keyword || '',
        search_by: this.$route.query.search_by || 'title',
      },
      search_by_options: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Keywords',
          value: 'keyword',
        },
        {
          text: 'Message',
          value: 'body',
        },
      ],
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  methods: {
    truncate(str, length) {
      return str ? `${str.substring(0, length || 20)}...` : '';
    },
    async getList() {
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        search_keyword: this.search.search_keyword,
        search_by: this.search.search_by,
      };
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.$parent.showLoader();
      const { data } = await chatbotTemplates.getList({
        workspace_id: this.activeWorkspace._id,
        query: options,
      });
      this.list = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        if (v.body_type === 'interactive' && v.action) {
          if (v.action_type === 'button') {
            v.button_content_mapped = v.action.buttons.map((btn) => {
              btn.type_str = 'Quick Reply';
              btn.text = btn.reply.title;
              btn.icon = 'comment';
              return btn;
            });
          }
          if (v.action_type === 'list') {
            v.list_content_mapped = v.action.sections[0].rows.map((list) => {
              list.text = list.title;
              if (list.description) {
                list.text += ` (${list.description})`;
              }
              list.icon = 'list';
              return list;
            });
          }
        }
        if (v.header) {
          v.header.type_str = upperFirst(v.header.type.toLowerCase());
          v.header.icon = v.header.type_str.toLowerCase();
          if (v.header.type_str.toLowerCase() === 'document') {
            v.header.icon = 'file-alt';
          } else if (v.header.type_str.toLowerCase() === 'text') {
            v.header.icon = 'paragraph';
          }
        }
        return v;
      });
      this.total = data.total;
      this.$parent.hideLoader();
    },
    doSearch() {
      this.search.page = 1;
      this.getList();
    },
    handlerBtnEdit(id) {
      this.$router.push({
        name: 'Chatbot Templates - Edit',
        params: {
          id,
        },
      });
    },
    handlerBtnDelete(id) {
      this.$confirm(this.$t('chatbot_template.confirm_delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await chatbotTemplates.delete({ id, workspace_id: this.activeWorkspace._id })
              .catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('chatbot_template.success_delete'),
              type: 'success',
            });
            this.getList();
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
  },
};
</script>
<style>
.el-select .el-input {
  min-width: 110px;
}
.input-with-select .el-input-group__append {
  background-color: #fff;
}
</style>
